h4 {
  font-size: 2vw;
}
.slick-dots {
  background: RGBA(255, 255, 255, 0);
  opacity: 1;
  padding: 0px 0px 0px 0px;
  box-shadow: none;
  border-width: 0px;
  border-style: solid;
  border-color: RGBA(0, 0, 0, 1);
  border-radius: 0px;
  bottom: 30px !important;
}
.slick-dots li {
  background: #cccccc;
  opacity: 1;
  box-shadow: none;
  border-width: 0px;
  border-style: solid;
  border-color: RGBA(0, 0, 0, 1);
  border-radius: 50px;
  margin: 4px;
  list-style-type: none !important;
  color: white !important;
}
.slide-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  width: 50%;
}
.btn-container {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  height: auto;
  width: auto;
  border: 0;
  box-shadow: none;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  margin-top: 2rem;
}
.btn-container .btn-explore {
  color: #ffffff;
  font-size: 93.5%;
  text-shadow: none;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  letter-spacing: 2px;
  word-spacing: normal;
  text-transform: none;
  font-weight: 400;
  background: #000000;
  background: RGBA(0, 0, 0, 0);
  opacity: 1;
  padding: 0.5em 0.7em;
  box-shadow: none;
  border-width: 2px;
  border-style: solid;
  border-color: RGBA(255, 255, 255, 1);
  border-radius: 0px;
}
.slick-prev {
  left: 2%;
  z-index: 1;
}
.slick-next {
  right: 2%;
}
.slick-initialized .slick-slide {
  position: relative;
}
.after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(34, 30, 31, 0.4);
}
.slide-item {
  position: relative;
  /* height: 35vh;  */
  /* width: 100%; */
  background-size: contain;
  alt: 'Project';
  float: left;
}
@media only screen and (min-width: 200px) and (max-width: 687px) {
  .slide-text {
    font-size: 1rem;
    z-index: 2;
    /* margin: 0em 0em 0em 0em; */
    top: 35%;
    right: 50%;
    width: 70%;

    /* padding: 17px; */
  }
  .slide-text-h4 {
    font-size: 1.1rem;
  }
  .social {
    position: absolute;
    right: 21px;
    top: 64px;
    padding-right: 15px;
  }
}
