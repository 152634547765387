.project-info {
  font-size: 15px;
}

.project-info .data-line {
  /* margin-top: 5px; */
  border-bottom: 1px solid #e3e3e3;
  /* margin-bottom: 11px; */
  padding: 10px;
  margin-top: 10px;
}

.project-info .data-line:last-child {
  border-bottom: 0px;
}

.project-info .data-line p,
.project-info .data-line a {
  display: inline-block;
  margin: 0;
  font-weight: bold;
}

.badge {
  margin-left: 5px;
  padding: 5px;
}

.slick-prev {
  left: 10px;
  z-index: 1;
}

.slick-next {
  right: 20px;
  z-index: 1;
}

.arc {
  color: #fff;
  background-color: #007bff;
}

.urb {
  color: #212529;
  background-color: #ffc107;
}

.eng {
  color: #fff;
  background-color: #28a745;
}

.int {
  color: #fff;
  background-color: #dc3545;
}

.con {
  color: #fff;
  background-color: #17a2b8;
}

.col-1-custom {
  -ms-flex: 0 0 4%;
  flex: 0 0 4%;
  max-width: 4%;
  margin-right: 3%;
}

.aside-text a {
  color: #939598 !important;
  /* color: rgb(111, 116, 124) !important; */
}
