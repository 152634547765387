.card-img-overlay {
  padding-top: 18%;
  background: rgba(14, 23, 62, 0.5);
}
@media only screen and (max-width: 767px) and (min-width: 300px) {
  .card-img-overlay h3 {
    padding-top: 0;
    font-size: 1.4rem;
  }
}

h3 {
  color: #d98032;
  letter-spacing: 4px;
  font-family: 'Helvetica' !important;
}

.head-cont {
  background-color: #0f173e;
  color: white;
}
