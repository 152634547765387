.contact-us-container {
    min-height: 50vh;
    padding-top: 50px;
}

h2 {
    margin: 0 0 10px 0;
    padding-bottom: 20px;
    text-transform: uppercase;
}

h2:after {
    display: block;
    /* content: " "; */
    color: #000;
    position: absolute;
    top: 40px;
    left: 17px;
    width: 100px;
    height: 1px;
    background: #000;
}

.group {
    margin-bottom: 10px !important;
}

.form-group .form-label {
    color: #6b86a0;
    display: flex;
    font-size: 14px !important;
    align-self: flex-start !important;
}

.form-group .form-control {
    background: #fff;
    outline: 0;
    box-shadow: none;
    border: 1px solid #000 !important;
    border-radius: 5px !important;
    width: 100%;
    height: 45px;
    padding: 0px 20px;
    padding-bottom: 0px !important;
    color: #000 !important;
}

.form-group textarea.form-control {
    height: 100px;
    text-align: left;
    width: 98%;
}

.form-group select.form-control {
    /* background: #fff 96% center no-repeat; */
    padding-right: 60px;
    width: 100%;
}


/* .form-inline .form-group {
    width: 49.5%;
} */

.custom-button {
    min-height: 315px;
    position: relative;
}

.custom-button .form-group {
    position: absolute;
    bottom: 15px;
    margin: 0 0px 0 30px;
}

.error-container {
    font-size: 12px !important;
    height: 10px !important;
    padding: 0px !important;
    padding-bottom: 15px !important;
}

.error-lable {
    color: rgba(148, 28, 7, 0.877) !important;
}

.error-input {
    border-color: rgba(148, 28, 7, 0.877) !important;
}

@media only screen and (max-width: 767px) and (min-width: 300px) {
    .custom-button {
        min-height: auto !important;
    }
    .custom-button .form-group {
        position: unset;
        margin: 0.5rem auto;
    }
    .form-group .form-control {
        border: 1px solid lightgrey;
    }
}