.latest-card {
  height: 26rem !important;
}
.card2 {
  height: 17rem !important;
}

.latest-card .card-title {
  font-size: 2.3rem;
}
.latest-card .card-img {
  min-height: -webkit-fill-available !important;

  /* min-height: auto; */
  max-height: auto !important;
}

.parent {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
.div1 {
  grid-area: 1 / 1 / 4 / 3;
}
.div2 {
  grid-area: 4 / 1 / 7 / 3;
}
.div3 {
  grid-area: 1 / 3 / 3 / 5;
}
.div4 {
  grid-area: 3 / 3 / 5 / 5;
}
.div5 {
  grid-area: 5 / 3 / 7 / 5;
}
.div6 {
  grid-area: 1 / 5 / 4 / 7;
}
.div7 {
  grid-area: 4 / 5 / 7 / 7;
}

@media only screen and (max-width: 678px) {
  .latest-card {
    height: 17rem !important;
  }
  .div1 {
    grid-area: 1 / 1 / 2 / 7;
  }
  .div2 {
    grid-area: 2 / 1 / 3 / 7;
  }
  .div3 {
    grid-area: 3 / 1 / 4 / 7;
  }
  .div4 {
    grid-area: 4 / 1 / 5 / 7;
  }
  .div5 {
    display: none;
  }
  .div6 {
    grid-area: 5 / 1 / 6 / 7;
  }
  .div7 {
    grid-area: 6 / 1 / 7 / 7;
  }
}

.bottom-border {
  border-bottom: 1px solid black;
  width: 19rem;
  margin: auto;
}

@media screen and (min-width: 679px) and (max-width: 870px) {
  .latest-card .card-title {
    font-size: 1.4rem !important;
    font-weight: bolder;
    margin-bottom: -2.2rem !important;
  }
  .card-text {
    font-size: 1rem !important;
  }
}

@media screen and (min-width: 870px) and (max-width: 988px) {
  .latest-card .card-title {
    /* font-size: 1.3rem !important; */

    margin-bottom: -1.25rem !important;
  }
}
