.filter-container {
  background-color: white;
  /* background-color: rgb(29,29,27); */
}
.filters {
  /* background: #e7e2e2 !important; */
  border: grey;
  /* background: #f2f2f607; */
}
.filters select {
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.18);
  background: #e7e2e2;
  border-radius: 0;
  box-shadow: none;
}
.filters h1 {
  margin: 0;
  font-size: 19px;
  color: rgb(01, 00, 57);
  font-weight: 700;
}
.filters #demo-2 input[type="search"] {
  color: transparent;
  cursor: pointer;
}
.filters input[type="search"] {
  background: #e7e2e2
    url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat
    21px center;
  border: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.filters input[type="text"] {
  padding: 10px 0px 10px 40px;

  background: #e7e2e2
    url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat
    10px center;
  color: #0f173e;
  border: none;
  font-size: 15px;
  border-radius: 10px;
  /* width: 100%; */
  text-decoration: none;
  display: inline-block;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.filters input[type="submit"] {
  background: #e7e2e2;
  color: #0f173e;
  border: none;
  font-size: 15px;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.filters button {
  background: #e7e2e2;
  color: #0f173e;
  border: none;
  font-size: 15px;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  margin-top: 0px;
}

hr {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}
