/* -- Fonts -- */

@font-face {
  font-family: 'Gotham';
  src: url('fonts/Gotham-Medium.eot');
  src: url('fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/Gotham-Medium.woff2') format('woff2'),
    url('fonts/Gotham-Medium.woff') format('woff'),
    url('fonts/Gotham-Medium.ttf') format('truetype'),
    url('fonts/Gotham-Medium.svg#Gotham-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaBold';
  src: url('fonts/Helvetica-Bold.eot');
  src: url('fonts/Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/Helvetica-Bold.woff2') format('woff2'),
    url('fonts/Helvetica-Bold.woff') format('woff'),
    url('fonts/Helvetica-Bold.ttf') format('truetype'),
    url('fonts/Helvetica-Bold.svg#Helvetica-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('fonts/Helvetica.eot');
  src: url('fonts/Helvetica.eot?#iefix') format('embedded-opentype'),
    url('fonts/Helvetica.woff2') format('woff2'),
    url('fonts/Helvetica.woff') format('woff'),
    url('fonts/Helvetica.ttf') format('truetype'),
    url('fonts/Helvetica.svg#Helvetica') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GothamBook';
  src: url('fonts/Gotham-Book.eot');
  src: url('fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
    url('fonts/Gotham-Book.woff2') format('woff2'),
    url('fonts/Gotham-Book.woff') format('woff'),
    url('fonts/Gotham-Book.ttf') format('truetype'),
    url('fonts/Gotham-Book.svg#Gotham-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

img {
  max-width: 100%;
}

/* -- Fonts -- */

:root {
  --main-grey: #c6c9ca;
  --main-dark-blue: #0f173e;
}

.dark-blue-color {
  color: var(--main-dark-blue);
}

.grey-color {
  color: var(--main-grey);
}

.App {
  text-align: center;
}

.dropdown-menu {
  top: 164%;
}

body {
  background-color: white;
  font-family: 'Gotham', sans-serif !important;
}

a {
  display: block;
}

.main {
  padding: 1rem 0;
}

a.href.active {
  color: #d98032 !important;
}

h1 {
  font-family: 'HelveticaBold', sans-serif;
  font-size: 2.5em;
  letter-spacing: 0.2em;
  color: #d98032;
}

h3 {
  font-family: 'HelveticaBold', sans-serif;
}

.btn-primary {
  background-color: #d98032 !important;
  border: none !important;
  border-radius: 20px !important;
  color: #fff !important;
  padding: 15px 40px 5px 40px;
}

.btn-secondary {
  background-color: #fff !important;
  border: none !important;
  border-radius: 20px !important;
  color: #d98032 !important;
  padding: 15px 40px 5px 40px;
}

.project-heading {
  border-left: 15px solid #d98032;
  padding: 7px 10px;
  height: 80px;
  color: #10214f;
}

.project-heading h4 {
  padding: 0;
  margin: 0;
}

.project-heading p {
  color: #d98032;
  font-size: 20px;
}

.navbar-collapse {
  justify-content: flex-end;
}

/* .bg-light {
background-color: #f8f9fabf !important;
} */

.navbar-brand {
  text-align: left;
}

.mt-5 {
  margin-top: 0.5rem !important;
}

#responsive-navbar-nav {
  margin-top: 1rem;
}

.navbar {
  background-color: #eeefef !important;
  height: auto;
  /* max-height: 100px; */
}

.navbar-nav .nav-link {
  color: #0f173e;
}
.navbar-expand-lg .navbar-nav .nav-link {
  margin: 0 1rem !important;
}

/* .navbar-nav .show .nav-link {
color: white;
} */

.navbar-nav {
  display: flex;
  /* background-color: #f8f9fabf; */
}

.navbar a {
  border-bottom: 2px transparent solid;
  padding-bottom: 0;
  padding-right: 2rem;
}

.navbar a:hover,
.navbar a.active {
  border-bottom: 2px #d98032 solid;
}

/* .nav-link:hover {
color: #D98032;
} */

.navbar .flex {
  justify-content: space-between;
}

/* .navbar-toggler {
float: right;
color: #0f173e;
} */

.logo {
  border: none !important;
  padding-bottom: 0px;
  padding-top: 0px;
}

.collapse {
  background-color: #eeefef;
}

.nav-item {
  padding: 0 1rem;
}

.getIntouch {
  padding: 20px 0 30px 0;
  margin-top: 3px;
}

.getIntouch h2 {
  margin: 0 0 10px 0;
  padding-bottom: 20px;
  text-transform: uppercase;
}

.getIntouch h2:after {
  display: block;
  content: ' ';
  color: #0f173e;
  position: absolute;
  top: 40px;
  left: 17px;
  width: 100px;
  height: 1px;
  background: #0f173e;
}

.form-group .form-control {
  background: #fff;
  outline: 0;
  box-shadow: none;
  border-radius: 0;
  width: 100%;
  height: 45px;
  padding: 10px 20px;
  border: 0;
  color: #000 !important;
  line-height: 22px;
  margin: 0.1rem;
}

.form-group textarea.form-control {
  height: 100px;
  text-align: left;
  width: 98%;
}

.form-group select.form-control {
  background: #fff 96% center no-repeat;
  padding-right: 60px;
  width: 100%;
}

.form-inline .form-group {
  width: 49.5%;
}

.custom-button {
  min-height: 315px;
  position: relative;
}

.custom-button .form-group {
  position: absolute;
  bottom: 15px;
  margin: 0 0px 0 30px;
}

.copyrights {
  /* padding: 30px 0; */
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 25px;
  background-color: #0f173e;
}

.flex-container a {
  color: #fff;
  display: inline-block;
}

.flex-container a {
  color: #fff;
  display: inline-block;
}

.flex-container .footer-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.footer-social a.lkln {
  background-position: -41px top;
}

.footer-social a.fb {
  background-position: left top;
}

.footer-social a.ins {
  background-position: -78px top;
}

.footer-social a {
  background: url(./Components/Footer/images/ico-social-footer.png) left top
    no-repeat;
  float: left;
  width: 27px;
  height: 27px;
  opacity: 1;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.footer-social .nav-item {
  display: inline-block;
  line-height: 16px;
  margin-left: 6px;
  padding-left: 6px;
}

@media only screen and (max-width: 767px) and (min-width: 300px) {
  .custom-button {
    min-height: auto !important;
  }
  .custom-button .form-group {
    position: unset;
    margin: 0.5rem auto;
  }
  .form-group .form-control {
    border: 1px solid lightgrey;
  }
}

.card-img-overlay {
  padding-top: 5%;
  background: rgba(14, 23, 62, 0.5);
}

@media only screen and (max-width: 767px) and (min-width: 300px) {
  .card-img-overlay h3 {
    padding-top: 0;
    font-size: 1.4rem;
  }
}

h3 {
  color: #fff;
  letter-spacing: 4px;
  font-family: 'Helvetica' !important;
}

.breadcrumb-custom {
  background: url(https://preview.colorlib.com/theme/repair/img/xbanner-bg.jpg.pagespeed.ic.eiWrWpgl4t.webp)
    center;
  background-size: auto;
  background-size: cover;
}

.middle-content {
  border-right: 2px solid #e3e3e3;
  border-left: 2px solid #e3e3e3;
}

.title {
  margin: 0 0 25px;
}

.content {
  padding-left: 100px;
  max-width: 440px;
}

.content p {
  font-size: 18px !important;
  color: black;
}

.content p {
  font-size: 22px;
  color: rgb(01, 00, 57);
  line-height: 32px;
}

.square-block.lightgreybox {
  background: #b2b5ba;
  color: black;
}

.our-agenda .square-block h2 {
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: 0;
}

.text-align-left {
  text-align: left;
}

.about-section {
  padding: 10px;
  margin-bottom: 60px;
}

.our-agenda .square-block {
  text-align: center;
  padding: 15px 20px 0;
  min-height: 160px;
}

.square-block {
  background: #e9e9e9;
  padding: 30px;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 30px;
  display: table;
  min-height: 400px;
}

.title-block {
  text-align: center;
  padding: 0 0 20px;
}

.square-block.greybox {
  background: rgb(01, 00, 57);
  color: #000;
}

.square-block .square-blockinner {
  display: table-cell;
  vertical-align: middle;
}

.bg-grey {
  background: #ccc;
  height: 200px;
}

.pt-2 {
  padding-top: 1.5rem !important;
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .bg-grey {
    display: none;
  }
}

.orangeBg {
  background-color: #de803c;
}

.orangeClr {
  color: #de803c;
}

.h1e {
  font-size: 3em;
  height: 115px;
  padding: 10px;
}

.greyBg {
  background-color: #939598;
  /* background-color: #e5e6e7; */
}

.uline {
  border-bottom: 2px solid #de803c;
}

.ulinebl {
  border-bottom: 2px solid #e5e6e7;
}

.filter-container {
  background-color: white;
  /* background-color: rgb(29,29,27); */
}

.filters {
  /* background: #e7e2e2 !important; */
  border: grey;
  /* background: #f2f2f607; */
}

.filters select {
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.18);
  background: #e7e2e2;
  border-radius: 0;
  box-shadow: none;
}

.filters h1 {
  margin: 0;
  font-size: 19px;
  color: rgb(01, 00, 57);
  font-weight: 700;
}

.filters #demo-2 input[type='search'] {
  color: transparent;
  cursor: pointer;
}

.filters input[type='search'] {
  background: #e7e2e2
    url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat
    21px center;
  border: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.filters input[type='text'] {
  padding: 10px 0px 10px 40px;
  background: #e7e2e2
    url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat
    10px center;
  color: #0f173e;
  border: none;
  font-size: 15px;
  border-radius: 10px;
  /* width: 100%; */
  text-decoration: none;
  display: inline-block;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.filters input[type='submit'] {
  background: #e7e2e2;
  color: #0f173e;
  border: none;
  font-size: 15px;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.filters button {
  background: #e7e2e2;
  color: #0f173e;
  border: none;
  font-size: 15px;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  margin-top: 0px;
}

hr {
  display: block;
  margin-top: -5px;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

.filter-container {
  /* background-color: rgb(29,29,27); */
  background-color: white;
}

.filters {
  /* background: #f2f2f607; */
  background-color: white;
}

.filters select {
  border: 0;
  /* border-bottom: 2px solid rgba(0,0,0,0.18); */
  border-bottom: 2px solid rgba(0, 0, 0, 0.18);
  background: white;
  border-radius: 0;
  box-shadow: none;
}

.filters h1 {
  margin: 0;
  font-size: 19px;
  /* color: rgb(01, 00, 57); */
  font-weight: 700;
}

a.href {
  color: black;
}

a.href.active {
  color: #d98032;
}

.color_active {
  color: #d98032;
}

.btn-orange {
  background-color: #d98032 !important;
  color: white !important;
}

.cursor {
  cursor: pointer;
}

.mt-5 {
  margin-top: 3rem !important;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border: 1px solid black;
  border-radius: 50%;
}

.container input:checked ~ .checkmark {
  border: 1px solid black;
  background-color: white;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 2.5px;
  left: 2.5px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: black;
}
