.filter-container {
  /* background-color: rgb(29,29,27); */
  background-color: white;
}
.filters {
  /* background: #f2f2f607; */
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
/* .filters select {
    border: 0;
    border-bottom: 2px solid rgba(0,0,0,0.18);
    background: #f2f2f6a2;
    border-radius: 0;
    box-shadow: none;
}
.filters h1 {
    margin: 0;
    font-size: 19px;
    color: rgb(01, 00, 57);
    font-weight: 700;
}
.filters #demo-2 input[type=search] {
    color: transparent;
    cursor: pointer;
}
.filters input[type=search] {
    background: #9cc3d4 url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 21px center;
    border: 0;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}

.filters input[type=text] {
    background: #9cc3d4 url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 10px center;
    border: 0;
    padding: 10px 0px 10px 40px;
    width: 55px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
} */
/*   */

/* sdasdklwajhbklasdyuhijok */

.profile-card {
  /* position: relative; */
  overflow: hidden;
  margin-bottom: 10px;
  box-shadow: 0px 2px 3px #222;
  top: 150px;
}
.profile-card:hover .profile-img img {
  transform: scale(1.2);
  opacity: 0.5;
}

.profile-card .profile-img img {
  width: 100%;
  height: auto;
  transition: transform 1s;
}
.profile-card .profile-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 40%;
  border: 3px solid #333;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: scale(1, 1);
  transition: all 0.5s linear;
}
.profile-card .profile-content::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 40%;
  border: 3px solid #222;
  border-left: 3px solid transparent;
  border-top: 3px solid transparent;
  transition: all 0.5s linear;
}
.profile-card:hover .profile-content:before {
  top: 20px;
  left: 20px;
}
.profile-card:hover .profile-content:after {
  bottom: 20px;
  right: 20px;
}

.profile-card .profile-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50% 4%;
  text-align: center;
}
.profile-card .profile-content:hover {
  background-color: rgba(203, 203, 203, 0.5);
}
.profile-card .profile-content .title {
  font-size: 24px;
  color: white;
  opacity: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  transform: translateY(-100px);
  transition: all 900ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
}
.profile-card:hover .title {
  opacity: 1;
  transform: translateY(0);
}
.profile-card:hover .title::after {
  content: '';
  display: block;
  width: 50%;
  margin: 10px auto;
  border-bottom: 2px solid #222;
}
.profile-card .profile-content .title > span {
  display: block;
  margin: 5px 0;
  font-size: 14px;
  text-transform: capitalize;
}
.profile-card .profile-content .social-link {
  margin: 20px 0;
  padding: 0;
  opacity: 0;
  transform: translateY(100px);
  transition: all 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s;
}
.profile-card:hover .social-link {
  opacity: 1;
  transform: translateY(0);
}
.profile-card .profile-content .social-link li {
  display: inline-block;
  list-style: none;
  margin: 0 4px;
}
.profile-card .profile-content .social-link li a {
  color: #333;
  width: 30px;
  height: 25px;
  line-height: 24px;
  text-align: center;
  border-radius: 5px;
  background-color: #fff;
}

.title a {
  color: black;
}
