.item-block {
    position: relative;
    margin-bottom: 0 !important;
    padding: 5px;
}

.card-container {
    position: relative;
    height: 100%;
    width: 100%;
    background-size: cover;
    alt: "Project";
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.card-img {
    height: 100%;
}

.hoverDiv {
    top: 100%;
    left: 100%;
    transform: translate(0%, -100%);
    background-color: #0f173e;
    opacity: 0.4;
    background-size: cover;
}


/* .projects-items .item-block .hoverDiv {
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden !important;
  height: 100%;
  transition: 0.5s ease;
  padding: 10px;
}
.projects-items .item-block:hover .hoverDiv {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  background: rgba(150, 241, 4, 0.6);
  height: 100%;
  width: 100%;
  transition: 0.5s;
  padding-left: 20px;
} */


/* .item-trans {
  padding-top: 35%;
} */


/* .hoverDiv .item-trans h3 {
  margin: 5px 0;
  color: #fff;
  font-size: 15px;
  opacity: 1;
} */


/* .hoverDiv .item-trans span {
  font-weight: 100;
  color: #fff;
  display: block;
}
.hoverDiv .item-trans a {
  border: 1px solid #fff;
  color: #fff;

  display: -webkit-inline-box;
  margin-top: 25px;
} */

.border {
    width: 30%;
    opacity: 1;
}

.top-card hr {
    background: white !important;
    border: .1px solid white;
    width: 15%;
    transform: translateX(-280%);
}

.card-img-overlay {
    padding: 5%;
}

.card-text.bottom-card.text-center {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 5%;
}

.projects-items {
    margin-left: 0px;
    margin-right: 0px;
}