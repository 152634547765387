.item-block {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
}

.projects-items-what {
    background-color: rgb(01, 00, 57);
    padding-top: 2rem;
}

.projects-items .item-block .hoverDiv {
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden !important;
    height: 100%;
    transition: .5s ease;
    padding-top: 8px;
    padding-left: 20px;
}

.projects-items .item-block:hover .hoverDiv {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    transition: .5s;
    padding-left: 20px;
}

.projects-items .item-block .hoverDiv .item-trans h1 {
    margin: 5px 0;
    color: #fff;
    font-size: 22px;
}

.projects-items .item-block .hoverDiv .item-trans span {
    font-weight: 300;
    color: #fff;
    display: block;
}

.projects-items .item-block .hoverDiv .item-trans a {
    border: 1px solid #fff;
    color: #fff;

    display: -webkit-inline-box;
    margin-top: 25px;
}

.card-border {
    padding-top: 15px;
    padding-bottom: 15px;
}

.card-container {
    /* position:relative; */
    width: 100%;
    background-size: cover;
    transition: all 250ms ease-in-out ;
}

.sub-container {
    position: absolute;
    /* padding: 20px; */
    bottom: 15px;
    transition: all 250ms ease-in-out !important;
    -webkit-transition: all 250ms ease-in-out !important;
    width: calc(100% - 30px);
    height: 0px;
    overflow: hidden;
    border-width: 4px;
    border-style: solid;
    color: #fff;
    border-color: rgb(01, 00, 57);
    background-color: rgba(78, 78, 78, 0.377);
    z-index: 10;
}
.sub-container a {
    border: 1px solid #fff;
    color: #fff;
    transition: all 250ms ease-in-out;
    display: -webkit-inline-box;
    margin-top: 25px;
}
.sub-container a:hover {
    text-decoration: none;
    transform: scale(1.01);
    border: none;
    padding: 5px;
    background-color: rgb(01, 00, 57);
    color: #fff;
    display: -webkit-inline-box;

}

.card-container:hover>.sub-container {
    height: calc(100% - 30px);
    padding: 20px;
    transition: all 250ms ease-in-out !important;
}

.card-border:hover {
    transition: all 250ms ease-in-out;
}