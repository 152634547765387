:root {
  --main-grey: #c6c9ca;
  --main-dark-blue: #0f173e;
  --main-orange: #de813b;
  --p-grey: #939598;
}

.dark-blue-color {
  color: var(--main-dark-blue);
}

.grey-color {
  color: var(--main-grey);
}

.p-grey {
  color: var(--p-grey);
}

.h2 {
  margin-top: 1rem !important;
  font-size: 2rem !important;
  color: #0f173e !important;
  font-weight: bold !important;
}

.h3 {
  margin-top: 2rem !important;
  font-size: 1.5rem !important;
  color: #0f173e !important;
  font-weight: bold !important;
}

.left-border-h1 {
  border-left: 1rem solid var(--main-orange);
  vertical-align: baseline;
  line-height: 4rem;
}

.our-history-header {
  padding-left: 1rem;
}
