.title {
  margin: 0 0 25px;
}
.content {
  padding-left: 100px;
  max-width: 440px;
}
.content p {
  font-size: 18px !important;
}
.content p {
  font-size: 22px;
  color: rgb(01, 00, 57);
  line-height: 32px;
}
.square-block.lightgreybox {
  background: #b2b5ba;
  color: #ffffff;
}
.our-agenda .square-block h2 {
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: 0;
}
.our-agenda .square-block {
  text-align: center;
  padding: 15px 20px 0;
  min-height: 160px;
}
.square-block {
  background: #e9e9e9;
  padding: 30px;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 30px;
  display: table;
  min-height: 400px;
}
.title-block {
  text-align: center;
  padding: 0 0 20px;
}
.square-block.greybox {
  background: rgb(01, 00, 57);
  color: #fff;
}
.square-block .square-blockinner {
  display: table-cell;
  vertical-align: middle;
}
.bg-grey {
  background: #ccc;
  height: 200px;
}
.pt-2 {
  padding-top: 1.5rem !important;
}
@media only screen and (min-width: 300px) and (max-width: 767px) {
  .bg-grey {
    display: none;
  }
}
.clients-content {
  padding: 20px;
}
.client-img {
  width: 100%;
}
.eight-cols .col-6,
.eight-cols .col-sm-6,
.eight-cols .col-md-3,
.eight-cols .col-lg-8th {
  padding: 2px !important;
}

@media (min-width: 992px) {
  .eight-cols .col-lg-8th {
    max-width: 12.5%;
  }
}
