.dialog {
  box-shadow: 0 8px 6px -6px black;
  position: static;
  left: 20%;
  top: 10%;
  z-index: 10;
}

.ss-slide-image img {
  cursor: zoom-in;
}

.image {
  cursor: zoom-out;
}

.slick-prev:before {
  content: "<";
  color: #fff;
  font-size: 50px;
}

.slick-next:before {
  content: ">";
  color: #fff;
  font-size: 50px;
}
.slider-container{
  margin-top: 20px;
}
.slideContainer{
  height: 40vh;
  /* height: 100%; */
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}