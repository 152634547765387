:root {
  --main-grey: #c6c9ca;
  --main-dark-blue: #0f173e;
}

.dark-blue-color {
  color: var(--main-dark-blue);
}

.grey-color {
  color: var(--main-grey);
}
.news-filter {
  margin-right: 5px !important;
}

.card-img {
  min-height: 19rem;

  max-height: 21rem;
}

.card-container {
  height: 100%;
  width: 100%;
  background-size: cover;
  alt: 'Project';
  display: flex;
  justify-content: center;
  overflow: hidden;
  /* display: none; */
}

.card-container .sub-container {
  opacity: 0;
  transition: 1s;
}

.card-container:hover .sub-container {
  opacity: 1;

  transition: 1s;
}

.card-container:hover .card-img {
  opacity: 0.5;
}
