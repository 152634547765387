:root {
  --main-grey: #c6c9ca;
  --main-dark-blue: #0f173e;
}

.dark-blue-color {
  color: var(--main-dark-blue);
}

.grey-color {
  color: var(--main-grey);
}

.home-container {
  /* min-height: 80vh !important; */
  background-color: white;
  /* background-color: rgb(105, 105, 69); */
}

.scopeTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: black;
  font-size: 2vw;
  z-index: 1;
}

.title img {
  width: 100%;
}

.title img:hover {
  transition: 0.5s;
  /* filter: brightness(0.8); */
}

.separateTitle {
  color: black;
  margin: 0;
}

.masthead {
  height: 100vh;
  min-height: 500px;
  background-image: url('images/header.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
}

.transbox {
  /* margin: 30px; */
  background-color: #0f173e;
  background-size: cover;
  margin-left: 0px;
  margin-right: 0px;
  border: none;
  opacity: 0.6;
  padding-left: 0px;
  padding-right: 0px;
}

.transbox p {
  color: white;
}

.c {
  height: 250px;
}

.c1 {
  background-color: #0f173e !important;
  color: white;
}

.c2 {
  background-color: #d98032 !important;
  color: white;
}

.c3 {
  background-color: #c7c9ca !important;
}

img.size {
  width: 30%;
  /* size: 3rem; */
  /* height: 50%; */
  align-self: center;
}
/* 
.card-body input[type='submit'] {
  background: #d98032;
  color: white;
  border: none;
  font-size: 15px;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.c1 input[type='submit'],
.c2 input[type='submit'] {
  background: white;
  color: #d98032;
  border: none;
  font-size: 15px;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
} */

.btn-primary {
  padding-top: 15px !important;
}

.latest-card {
  border: none;
}

.card-group {
  align-content: center;
}

.card-text {
  text-align: left;
  margin-top: 3rem;
}

.footer-content {
  background: white;
  padding-top: 40px;
  color: #0f173e;
  text-align: center;
}

.footer-content h3 {
  font-size: 1.8rem;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 3rem;
}

.footer-content p {
  max-width: 500px;
  margin: 10px auto;
  line-height: 28px;
  font-size: 14px;
}

.socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 0 0;
  padding: 10px;
}

.socials li {
  /* margin: 0 10px; */
}

.socials img {
  /* width: 10rem; */
}

.cont .card-body {
  height: 400px;
}

.h-560 {
  height: 560px;
}

/*
.h-600 {
    height: 600px;
} */
