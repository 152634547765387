:root {
  --main-grey: #c6c9ca;
  --main-dark-blue: #0f173e;
}

.dark-blue-color {
  color: var(--main-dark-blue);
}

.grey-color {
  color: var(--main-grey);
}

.border {
  margin: 0 auto !important;
  width: 80% !important;
  opacity: 1 !important;
  border: 1px solid #0f173e !important;
}

.bottom-border-h {
  border-bottom: 1px solid black;
  width: 16rem;
  margin: auto;
}
